@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  justify-content: space-between;
}

a {
  text-decoration: none;
}

.site-layout {
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.ant-modal-confirm-body {
  > .delete-icon {
    color: red !important;
  }

  > .info-icon {
    color: #2a8ff7 !important;
  }

}

.input-content {
  border-radius: $input-radius;
}


.button-container {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.status-name-content {
  display: inline-block;
  border-radius: 4px;
  padding: 3px 8px;

  &.draft {
    @include statusStyle(#ed3833, rgba(237, 56, 51, 0.07), #ed3833)
  }

  &.updated {
    @include statusStyle(#4768ed, rgba(71, 104, 237, 0.07), #4768ed);
  }

  &.published {
    @include statusStyle(#5cc431, rgba(92, 196, 49, 0.07), #5cc431);
  }

  //revenue statuses
  &.PENDING {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF);
  }

  &.CANCELED {
    @include statusStyle(#F5222D, #FFF1F0, #FFA39E)
  }

  &.PAYED {
    @include statusStyle(#52C41A, #F6FFED, #B7EB8F);
  }
  
  &.PARTIAL_REFUNDED{
    @include statusStyle(#c49c1a, #fff7ed, #ebd68f);
  }

  &.REFUNDED{
    @include statusStyle(#a817d4, #f8edff, #c68feb);
  }
  //----------------------------------------------

  &.NEW {
    @include statusStyle(#facf70, #FFFBE6, #FFE58F);
  }

  &.PENDING_ACTIVATION {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF);
  }

  &.PENDING_PROFILE {
    @include statusStyle(#722ED1, #F9F0FF, #D3ADF7);
  }

  &.PUBLISH_ACTIVE, &.ACTIVE {
    @include statusStyle(#52C41A, #F6FFED, #B7EB8F);
  }

  &.DECLINED {
    @include statusStyle(#e0868a, #FFF1F0, #FFA39E);
  }
;

  &.PENDING_REVIEW {
    @include statusStyle(#41d2d2, #e1f6f6, #74e2e2);
  }

  &.BLOCK {
    @include statusStyle(#F5222D, #FFF1F0, #FFA39E)
  }

  &.DRAFT {
    @include statusStyle(#F5222D, #FFF1F0, #FFA39E)
  }

  &.SUSPENDED {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF)
  }


  &.READ {
    @include statusStyle(#2F54EB, #f7f9ff, #93a7f8);
  }

  &.RESPONDED {
    @include statusStyle(#722ED1, #f6f6f6, #b583fa);
  }

  &.PENDING_RESPONSE {
    @include statusStyle(#41d2d2, #e1f6f6, #74e2e2);
  }

  &.ISSUE {
    @include statusStyle(#399909, #f0ffe9, #9cde7d);
  }

  &.CLOSED {
    @include statusStyle(#F5222D, #FFF1F0, #FFA39E);
  }

  &.ABANDONED {
    @include statusStyle(#e2313b, #e8d4d387, #FFA39E);
  }

  &.MENTORING {
    @include statusStyle(#722ED1, #f6f6f6, #b583fa);
  }

  &.COURSE {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF)
  }

}


.schedule_types {
  &.round {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100px;
  }

  &.button_style {
    display: inline-block;
    border-radius: 4px;
    padding: 3px 8px;
    color: #ffffff;
  }

  &.DRAFT {
    background-color: #b4c9ea;
  }

  &.PLANNED {
    background-color: #f2ca79;
  }

  &.IN_PROGRESS {
    background-color: #f1f257;
  }

  &.COMPLETED {
    background-color: #57c07d;
  }

  &.CANCELLED {
    background-color: #d70606;
  }

  &.TERMINATED {
    background-color: #ee6b75;
  }
}

.modal-info-content {
  .ant-modal-content {
    border-radius: 5px;
  }
}

.table-title-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-layout {
  padding: 20px 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.main-content-layout {
  padding: 20px;
  min-height: 72vh;

  .empty-content {
    display: flex;
    align-items: center;
    height: 60vh;
    justify-content: center;
  }
}

.footer-content-layout {
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin-top: 20px;
  display: grid;
  align-items: center;
  justify-content: end;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.ant_des_select {
  .ant-select-selector {
    border-radius: 5px !important;
  }
}

.space_between {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.flex_end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.flex_center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.full_width {
  width: 100%;
}

.link_text_style {
  color: #597EF7;
  text-decoration: underline;
  cursor: pointer;
}